import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
//import Icon from '../../Icon';
import './styles.less';
import actions from './actions';
import {
  navIconsArr,
  navIconsAdmin,
  NavLinkWithIcon,
  //NavLinkOnlyIcon,
} from './navigation';

export const SidebarComponent = ({ nav, isAdmin, changeSizeSidebar,openSidebar, ...props }) => {
  const { isShowMenu, isShowSideBar, theme, isMobile } = nav;
  const location = useLocation();
  const { t } = useTranslation();

  const isActive = (path) => !location.pathname.includes(path);

  const menuIcons = isAdmin
    ? navIconsAdmin
    : navIconsArr['desktop'];

  return (
    <>
      {/*<div
        className={cls('main-sidebar-menu_container', {
          'closed-sidebar': isMobile || isShowMenu,
          'opened-sidebar': !isShowMenu && !isMobile,
          'small-sidebar': isShowSideBar,
          'big-sidebar': !isShowSideBar && !isMobile,
        })}
      >
        <div
          className={cls('main-sidebar-menu', {
            'closed-sidebar': isMobile || isShowMenu,
            'opened-sidebar': !isShowMenu && !isMobile,
            'small-sidebar': isShowSideBar,
            'big-sidebar': !isShowSideBar && !isMobile,
            firstLinkSelected: !isActive(
              isAdmin ? '/admin/portfolio' : '/dashboard',
            ),
            lastLinkSelected: !isActive(
              isAdmin ? '/admin/shopify' : '/boosters',
            ),
          })}
        >
          <div className="main-sidebar-menu_wrapper">
            {menuIcons.map(({ url, iconMob }) => (
              <NavLinkOnlyIcon
                key={url}
                url={url}
                theme={theme}
                active={isActive(url)}
                iconType={iconMob}
                isOpened={isShowSideBar}
              />
            ))}
          </div>
        </div>
      </div>*/}
      <aside
        className={cls('main-sidebar', {
          'closed-sidebar': !isShowMenu,
          'opened-sidebar': isShowMenu,
          //'small-sidebar': isShowSideBar,
          'big-sidebar': !isShowSideBar && !isMobile,
          'hidden': location.pathname.includes('shopify/app')
        })}
      >
        <div className="main-sidebar_wrap">

          {props.children}

          <Divider className="main-sidebar_divider" />

          {menuIcons.map(({ url, iconDesk, text, comingSoon, markText, withDivider }) => {
            const navIcon = (
              <NavLinkWithIcon
                key={url}
                url={url}
                theme={theme}
                text={t(text)}
                active={isActive(url)}
                iconType={iconDesk}
                isOpened={isShowSideBar}
                isSoon={comingSoon}
                markText={markText ? t(markText) : null}
                openSidebar={() => openSidebar(!isShowMenu)}
              />
            );
            const divider = <Divider className="main-sidebar_divider" style={{margin: '12px 0'}}/>;
            return withDivider ? (
              <Fragment key={url}>
                {navIcon} {divider}
              </Fragment>
            ) : (
              navIcon
            );
          })}
        </div>
        <div className="main-sidebar_bottom">
          {/*<button
            type="button"
            onClick={() => changeSizeSidebar(!isShowSideBar)}
            className={cls('main-sidebar_reduce-size', {
              'small-sidebar': isShowSideBar,
            })}
          >
            <Icon
              className="sidebar-link_icon"
              type="chevron_left"
              role="icon"
            />
          </button>*/}
          {
            !isAdmin
              ? (
                <NavLinkWithIcon
                  url="/setting"
                  theme={theme}
                  text={t('sidebar.settings')}
                  active={isActive('/setting')}
                  iconType="settings"
                  isOpened={isShowSideBar}
                  openSidebar={() => openSidebar(!isShowMenu)}
                />)
              : null
          }
        </div>
      </aside>
    </>
  );
};

SidebarComponent.defaultProps = {};

SidebarComponent.propTypes = {
  nav: PropTypes.shape({
    isShowMenu: PropTypes.bool,
    isShowSideBar: PropTypes.bool,
    isMobile: PropTypes.bool,
    theme: PropTypes.string,
  }),
  changeSizeSidebar: PropTypes.func,
};

const mapStateToProps = (state) => ({
  nav: state.nav,
  isAdmin: state.auth.isAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  changeSizeSidebar(value) {
    dispatch(actions.changeSizeSidebar('isShowSideBar', value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarComponent);
