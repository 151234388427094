import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cls from 'classname';

import { Spin, Divider } from 'antd';

import { footerModal } from '../UpcomingDropPage/modal-footer';
import openNotification from '../../Components/Notification';
import Modal from '../../Components/Modal';
import Creators from './reducer';

import './styles.less';

const ModalShopifyCharge = (
  {
    isMobile,
    theme,
    userInfo,
    shopifyStore,
    isVisible,
    closeModal,
    data,
    return_url,
    ...props
  }) => {

  const { loading, createChargeErrors, createChargeResult, checkChargeErrors, checkChargeResult } = shopifyStore;
  const { t } = useTranslation();
  //const history = useHistory();

  useEffect(() => {
    if(createChargeResult || createChargeErrors){
      if(createChargeResult){
        /*openNotification({
          message: t('Your Shopify store has been successfully disconnected'),
          style: { minWidth: '716px' },
        });*/
        closeModal()
      }
      if(createChargeErrors){
        openNotification({
          message: t('Something went wrong, kindly try again'),
          style: { minWidth: '716px' },
          type: 'error'
        });
      }
      props.createChargeShopifyStoreReset()
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  },[createChargeResult, createChargeErrors])

  useEffect(() => {
    if(checkChargeResult || checkChargeErrors){
      if(checkChargeResult){
        openNotification({
          message: t('Your payment has been successfully completed'),
          style: { minWidth: '716px' },
        });
      }
      if(checkChargeErrors){
        openNotification({
          message: checkChargeErrors?.errors || t('Something went wrong, kindly try again'),
          style: { minWidth: '716px' },
          type: 'error'
        });
      }
      props.checkChargeShopifyReset()
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  },[checkChargeResult, checkChargeErrors])

  const handleOk = () => {
    if(loading) return
    const {store, ...other} = data
    props.createChargeShopifyStore(other)
  };

  return (
    <Modal
      className={cls('shopify-delete')}
      title={t('Buy more imports')}
      visible={isVisible}
      width={512}
      handleOk={handleOk}
      handleClose={closeModal}
      isMobile={isMobile}
      destroyOnClose={true}
      footer={footerModal({
        isMobile: isMobile,
        handleCancel: closeModal,
        textBtnCancel: t('Cancel'),
        handleOk: handleOk,
        textBtnOk: t('Buy'),
        isDisabledBtnOk: false,
      })}
    >
      <Spin spinning={loading}>
        <p>
          {
            t('Increase the number of imports for _store_', {store: data?.store || 'store' })
          }
        </p>
        <Divider />
      </Spin>
    </Modal>
  );
};

ModalShopifyCharge.defaultProps = {
  shopifyStore: {
    loading: false,
    results: [],
    //isVisibleModal: null,
  },
};

ModalShopifyCharge.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
  userInfo: PropTypes.object,
  shopifyStore: PropTypes.shape({
    loading: PropTypes.bool,
    //createChargeErrors: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    createChargeResult: PropTypes.bool,
    checkChargeResult: PropTypes.bool,
    results: PropTypes.array,
    //isVisibleModal: PropTypes.string,
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    domain: PropTypes.string,
  })
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  userInfo: state.auth.userInfo,
  shopifyStore: state.shopifyStore,
});

const mapDispatchToProps = (dispatch) => ({
  createChargeShopifyStore: data => dispatch(Creators.createChargeShopifyStoreRequest(data)),
  createChargeShopifyStoreReset: () => dispatch(Creators.createChargeShopifyStoreReset()),
  checkChargeShopifyReset: () => dispatch(Creators.checkChargeShopifyReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalShopifyCharge);
