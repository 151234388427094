import React from 'react';
import cls from "classname";

const AlertTriangleError = ({width, height, className, color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '16'}
      height={height ? height : '16'}
      viewBox="0 0 16 16"
      className={cls(className, 'icon')}
      fill={color ? color : '#D71313'}
    >
      <path
        d="M15.0397 10.8666L9.92641 2.38663C9.71965 2.06317 9.43478 1.79697 9.09806 1.61258C8.76135 1.42819 8.38364 1.33154 7.99974 1.33154C7.61585 1.33154 7.23813 1.42819 6.90142 1.61258C6.56471 1.79697 6.27984 2.06317 6.07307 2.38663L0.959741 10.8666C0.77911 11.1677 0.680934 11.5111 0.675082 11.8622C0.669231 12.2132 0.755911 12.5597 0.926408 12.8666C1.12353 13.2121 1.40885 13.4991 1.75321 13.6982C2.09757 13.8974 2.48862 14.0015 2.88641 14H13.1131C13.5082 14.0042 13.8975 13.9041 14.2417 13.7098C14.5858 13.5155 14.8726 13.2338 15.0731 12.8933C15.2486 12.5831 15.338 12.2317 15.3322 11.8754C15.3263 11.5191 15.2254 11.1708 15.0397 10.8666ZM13.9064 12.2333C13.8263 12.371 13.71 12.4842 13.5702 12.5606C13.4303 12.637 13.2723 12.6736 13.1131 12.6666H2.88641C2.72722 12.6736 2.56915 12.637 2.42931 12.5606C2.28947 12.4842 2.1732 12.371 2.09307 12.2333C2.03456 12.1319 2.00376 12.017 2.00376 11.9C2.00376 11.7829 2.03456 11.668 2.09307 11.5666L7.21307 3.07996C7.30508 2.95843 7.42399 2.85987 7.56047 2.79201C7.69696 2.72415 7.84731 2.68883 7.99974 2.68883C8.15217 2.68883 8.30252 2.72415 8.43901 2.79201C8.5755 2.85987 8.69441 2.95843 8.78641 3.07996L13.8997 11.56C13.9605 11.6615 13.9932 11.7774 13.9943 11.8957C13.9955 12.0141 13.9652 12.1306 13.9064 12.2333Z"
        fill="#D71313"/>
      <path
        d="M7.99967 11.3333C8.36786 11.3333 8.66634 11.0349 8.66634 10.6667C8.66634 10.2985 8.36786 10 7.99967 10C7.63148 10 7.33301 10.2985 7.33301 10.6667C7.33301 11.0349 7.63148 11.3333 7.99967 11.3333Z"
        fill="#D71313"/>
      <path
        d="M7.99967 5.33337C7.82286 5.33337 7.65329 5.40361 7.52827 5.52864C7.40325 5.65366 7.33301 5.82323 7.33301 6.00004V8.66671C7.33301 8.84352 7.40325 9.01309 7.52827 9.13811C7.65329 9.26314 7.82286 9.33337 7.99967 9.33337C8.17649 9.33337 8.34605 9.26314 8.47108 9.13811C8.5961 9.01309 8.66634 8.84352 8.66634 8.66671V6.00004C8.66634 5.82323 8.5961 5.65366 8.47108 5.52864C8.34605 5.40361 8.17649 5.33337 7.99967 5.33337Z"
        fill="#D71313"/>
    </svg>
  );
};

export default AlertTriangleError;
