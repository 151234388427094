import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

const colors = {
  0: '#E7FFF1',
  1: '#E7FFF1',
  2: '#E7FFF1',
  3: '#FFF9E8',
  4: '#FFF4F4',
  5: '#FFF4F4',
};
const colorsDark = {
  0: '#001D10',
  1: '#001D10',
  2: '#001D10',
  3: '#211800',
  4: '#260000',
  5: '#260000',
};

const Ellipse = ({ width, height, className, outline, theme }) => {
  let themeColor = theme === 'dark' ? colorsDark[outline] : colors[outline];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 24"
      fill="none"
      className={cls(className, 'icon icon-ellipse')}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48 24C48 20.8483 47.3792 17.7274 46.1731 14.8156C44.967 11.9038 43.1992 9.25804 40.9706 7.02944C38.742 4.80083 36.0962 3.033 33.1844 1.82689C30.2726 0.620778 27.1517 -1.37766e-07 24 0C20.8483 1.37766e-07 17.7274 0.620779 14.8156 1.82689C11.9038 3.033 9.25804 4.80083 7.02944 7.02944C4.80083 9.25804 3.033 11.9038 1.82689 14.8156C0.620778 17.7274 -2.75532e-07 20.8483 0 24H9.6C9.6 22.109 9.97247 20.2364 10.6961 18.4894C11.4198 16.7423 12.4805 15.1548 13.8177 13.8177C15.1548 12.4805 16.7423 11.4198 18.4894 10.6961C20.2364 9.97247 22.109 9.6 24 9.6C25.891 9.6 27.7636 9.97247 29.5106 10.6961C31.2577 11.4198 32.8452 12.4805 34.1823 13.8177C35.5195 15.1548 36.5802 16.7423 37.3039 18.4894C38.0275 20.2364 38.4 22.109 38.4 24H48Z"
        fill={outline > 5 ? '#D71313' : themeColor}
      />
      <path
        d="M0 24C2.56379e-07 21.0674 0.537484 18.1596 1.58581 15.4207L10.5515 18.8524C9.92249 20.4957 9.6 22.2404 9.6 24H0Z"
        fill={outline >= 1 ? '#16895A' : 'transparent'}
      />
      <path
        d="M0 24C4.45825e-07 18.9004 1.6244 13.9333 4.63763 9.81907L12.3826 15.4914C10.5746 17.96 9.6 20.9402 9.6 24H0Z"
        fill={outline >= 2 ? '#16895A' : 'transparent'}
      />
      <path
        d="M0 24C5.21542e-07 18.0343 2.22187 12.2824 6.23239 7.86582C10.2429 3.44928 15.7545 0.684714 21.6927 0.111171L22.6156 9.6667C19.0527 10.0108 15.7457 11.6696 13.3394 14.3195C10.9331 16.9694 9.6 20.4206 9.6 24H0Z"
        fill={outline >= 3 ? '#F6B60E' : 'transparent'}
      />
      <path
        d="M0 24C4.14974e-07 19.2532 1.40758 14.6131 4.04473 10.6663C6.68189 6.71953 10.4302 3.64339 14.8156 1.82689C19.201 0.0103868 24.0266 -0.464892 28.6822 0.461155C33.3377 1.3872 37.6141 3.67298 40.9706 7.02944L34.1823 13.8177C32.1685 11.8038 29.6026 10.4323 26.8093 9.87669C24.016 9.32106 21.1206 9.60623 18.4894 10.6961C15.8581 11.786 13.6091 13.6317 12.0268 15.9998C10.4445 18.3679 9.6 21.1519 9.6 24H0Z"
        fill={outline >= 4 ? '#D71313' : 'transparent'}
      />
      <path
        d="M0 24C4.80523e-07 18.5035 1.88669 13.1735 5.3447 8.901C8.80271 4.62852 13.6225 1.67246 18.9983 0.526968C24.3742 -0.618528 29.9803 0.115954 34.8797 2.60764C39.779 5.09932 43.6746 9.1972 45.9153 14.2163L37.1492 18.1298C35.8047 15.1183 33.4674 12.6596 30.5278 11.1646C27.5882 9.66957 24.2245 9.22888 20.999 9.91618C17.7735 10.6035 14.8816 12.3771 12.8068 14.9406C10.732 17.5041 9.6 20.7021 9.6 24H0Z"
        fill={outline >= 5 ? '#D71313' : 'transparent'}
      />
    </svg>
  );
};
Ellipse.defaultProps = {
  width: 48,
  height: 24,
  outline: 0,
  theme: 'light',
};

Ellipse.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  outline: PropTypes.number,
  theme: PropTypes.string,
};

export default Ellipse;
