import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import Button from '../Button';
import Icon from '../../Icon';
import { EventHandler } from '../../Utils/event-handler';

const SaveToCollectionMsg = ({ keyForClose, text, isSave }) => {
  const { t } = useTranslation();

  return (
    <div className="notification__body">
      <div className="notification__wrapper">
        <div className="notification__icon">
          <Icon role="icon" outline type="bookmark" />
        </div>
        <div className="notification__text-block">
          <p className="notification__name">{text}</p>
          <p className="notification__action">
            {t(isSave ? 'notification.save_text' : 'notification.unsave_text')}
          </p>
        </div>
      <Button
        className="btn--notification"
        text={t('notification.save_btn')}
        onClick={() => {
          // notification.close(keyForClose);
          notification.destroy();
          EventHandler.emit('open-modal', { text: 'hello world' });
        }}
      />
      </div>
    </div>
  );
};

SaveToCollectionMsg.defaultProps = {};

SaveToCollectionMsg.propTypes = {
  text: PropTypes.string.isRequired,
  isSave: PropTypes.bool,
  keyForClose: PropTypes.string.isRequired,
};

export const DefaultMsg = ({ text, icon, iconOutline }) => (
  <div className="notification__body">
    <div className="notification__wrapper">
      <div className="notification__icon">
        <Icon role="icon" outline={!!iconOutline} type={icon || 'folder'} />
      </div>
      <div className="notification__text-block">
        <p className="notification__name">{text}</p>
      </div>
    </div>
  </div>
);

DefaultMsg.defaultProps = {};

DefaultMsg.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  iconOutline: PropTypes.bool,
  icon: PropTypes.any,
};

export default SaveToCollectionMsg;
