import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cls from 'classname';

import { Spin, Input, Button } from 'antd';

import openNotification from '../../Components/Notification';
import Modal from '../../Components/Modal';
import Images from '../../Images';
import Creators from './reducer';

import './styles.less';

const text = 'Enter your existing Shopify store URL below, and you will be redirected to Shopify to connect your account to Dropship.';

const ModalShopifyConnect = (
  {
    isMobile,
    theme,
    userInfo,
    shopifyStore,
    ...props
  }) => {

  const { loading, isVisibleModal, verifyErrors, verifyResult, initialUrl } = shopifyStore;
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [storeName, setStoreName] = useState(null);
  const REDIRECT_URL_TO_OUR_SITE = document?.location?.origin + '/connect-shopify/verify' //href

  useEffect(()=>{
    if(location?.search && location?.search.includes('.myshopify.com') && location?.pathname !=='/'){
      props.verifyShopifyStore(location?.search)
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  },[])

  useEffect(() => {
    if(verifyResult || verifyErrors){
      history.replace(initialUrl || '/setting')
      if(verifyResult){
        openNotification({
          message: t('Your Shopify store has been successfully connected'),
          style: { minWidth: '716px' },
        });
        handleClose()
      }
      if(verifyErrors){
        openNotification({
          message: t('Something went wrong, kindly try again'),
          style: { minWidth: '716px' },
          type: 'error'
        });
      }
      props.verifyShopifyStoreReset()
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  },[verifyResult, verifyErrors])

  const handleClose = () => {
    if(storeName) setStoreName(null)
    if(isVisibleModal === 'connect') props.closeModalConnect();
  };

  const handleOk = () => {

  };

  function handleChange(e) {
    const { value } = e.target;
    setStoreName( value );
  }

  return (
    <Modal
      className={cls('shopify-connect')}
      title={t('Connect Store')}
      visible={isVisibleModal === 'connect'}
      width={592}
      handleOk={handleOk}
      handleClose={handleClose}
      isMobile={isMobile}
      destroyOnClose={true}
      footer={false}
    >
      <Spin spinning={loading}>
        <img src={Images[theme === 'dark' ? 'shopifyConnectDark' : 'shopifyConnect']}
             alt=""
        />
        <p>{t(text)}</p>

        <div className='input-store-wrapper'>
          <Input
            suffix=".myshopify.com"
            placeholder={t('store-name')}
            className='input-store'
            value={storeName}
            onChange={handleChange}
            //onPressEnter={e => handleConnectStore()}
          />

          <Button
            type="primary"
            className={cls('btn-store', {
              disabled: !storeName,
            })}
            disabled={!storeName || loading}
            //onClick={handleConnectStore}
            href={`https://${storeName}.myshopify.com/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_STORE_KEY}&scope=write_products&redirect_uri=${REDIRECT_URL_TO_OUR_SITE}&state=${userInfo?.id}&grant_options[]=value`}
          >
            {t('Connect')}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

ModalShopifyConnect.defaultProps = {
  shopifyStore: {
    loading: false,
    results: [],
    isVisibleModal: null,
  },
};

ModalShopifyConnect.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
  userInfo: PropTypes.object,
  shopifyStore: PropTypes.shape({
    loading: PropTypes.bool,
    //verifyErrors: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    verifyResult: PropTypes.bool,
    results: PropTypes.array,
    isVisibleModal: PropTypes.string,
    initialUrl: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  userInfo: state.auth.userInfo,
  shopifyStore: state.shopifyStore,
});

const mapDispatchToProps = (dispatch) => ({
  verifyShopifyStore: (verify_params) => dispatch(Creators.verifyShopifyStoreRequest({verify_params})),
  verifyShopifyStoreReset: () => dispatch(Creators.verifyShopifyStoreReset()),
  closeModalConnect: () => dispatch(Creators.changeVisibleModalShopify({ isVisibleModal: null, initialUrl: null })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalShopifyConnect);
