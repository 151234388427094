import React from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import Images from '../../Images';

import utils from '../../Utils/utils';
import '../../Components/PlanCardOnboarding/styles.less';

const BundleCard = (
  {
    bundle,
    onSelect,
    selected,
    bundleIndex,
    ...props
  }) => {
  const { t } = useTranslation();

  return (
    <div className={'plan-card-onboarding'}>

      <div className={cls('img-wrp')}/>
      <img src={Images[`plan_${bundleIndex}`]}
           alt=""
           className={cls(`drop-size_${bundleIndex}`)}
      />

      <div className="info-wrp">
        <span className="info-title">
          {utils.toUpperLatter(bundle?.title)}
         {/* { bundle?.title === 'Standard' ? <span className={cls('mark')}>most popular</span> : null }*/}
        </span>
        <span className="info-text">{bundle?.description}</span>
      </div>


      <div className="info-wrp info-wrp_border">
      <div className="info-wrp" style={{width: 'auto'}}>
        <span className="info-text">{t('Product Imports')}</span>
        <span className="info-title">{`${bundle?.size}`}</span>
      </div>

      <div className="info-wrp" style={{width: 'auto'}}>
        <span className="info-text">{t('Price')}</span>
        <span className="info-title">{`$${bundle?.price}`}</span>
      </div>
      </div>

      <Button type='primary'
              className={cls('btn-primary',
                {'active': selected?.id === bundle.id})}
              onClick={() => onSelect(bundle)}
      >
        {t('Buy')}
      </Button>

    </div>
  );
};

export default BundleCard;
