import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    getProductDatabaseFiltersRequest: null,
    getProductDatabaseFiltersSuccess: ['payload'], // payload: { data }
    getProductDatabaseFiltersFailure: ['payload'], // payload: { errors }
  }
);

export const ProductDatabaseTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  errors: false,
  filters: {},
});

/* ------------- Functions for reducer cases ------------- */

const getProductDatabaseFiltersRequest = (state) =>
  state.merge({ loading: true });

const getProductDatabaseFiltersSuccess = (state, { payload: data }) =>
  state.merge({ loading: false, filters: data });

const getProductDatabaseFiltersFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCT_DATABASE_FILTERS_REQUEST]: getProductDatabaseFiltersRequest,
  [Types.GET_PRODUCT_DATABASE_FILTERS_SUCCESS]: getProductDatabaseFiltersSuccess,
  [Types.GET_PRODUCT_DATABASE_FILTERS_FAILURE]: getProductDatabaseFiltersFailure,

});
