import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import IconWithText from '../../../Components/Text';
import ThemeSwitcher from '../../../Components/ThemeSwitcher';
import { Divider } from 'antd';

const MenuDropdown = (
  {
    theme,
    changeTheme,
    logout,
    isAdmin,
    isMobile,
    shopifyStore,
    openModalShopifyConnect,
    disabledConnect
  }) => {
  const { t } = useTranslation();

  const storeList = shopifyStore?.results || []

  const handleOpenConnectModal = () => {
    if(disabledConnect) return
    openModalShopifyConnect()
  }

  if (isAdmin) {
    return (
      <div className="user">
        <div className="user_logout user_logout_admin" onClick={logout}>
          <IconWithText
            text={t('Log out')}
            icon={() => <Icon type="logout" role="icon"/>}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="user">
        <div className="user_links">
          <div className="user_link-wrap">
            <Link to="/setting" className="user_link">
              <IconWithText
                text={t('Manage Account')}
                size={16}
                icon={() => <Icon type="user" role="icon"/>}
              />
            </Link>
            <div className="btn-wrap">
              <ThemeSwitcher theme={theme} changeTheme={changeTheme}/>
              <div className="user_logout" onClick={logout}>
                <Icon type="logout" width={16} height={16} role="icon"/>
              </div>
            </div>
          </div>
        </div>

        {
          storeList?.length
            ? (<>
              <Divider style={{ margin: '16px 0' }}/>
              <ul className="user_store-list">
                {
                  storeList.map(({ id, name, domain, ...other }) => (
                    <li key={id} className="user_store-item">
                      <span className="user_store-name">{name}</span>
                      <span className="user_store-link">{domain}</span>
                    </li>
                  ))
                }
              </ul>
            </>)
            : null
        }

        {
          isMobile
            ? null
            : (
              <>
                <Divider style={{ margin: '16px 0' }}/>
                <span className={cls('text-link', {disabled: disabledConnect})} onClick={handleOpenConnectModal}>
                  <Icon type="plus" role="icon" width={16} height={16}/>
                  {t('Connect New Store')}
                </span>
              </>)
        }
      </div>
    );
  }
};

MenuDropdown.propTypes = {
  theme: PropTypes.string.isRequired,
  changeTheme: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MenuDropdown;
