import React from 'react'

const AttentionOutline = (props) => (
  <svg width={props.width ? props.width : 44}
       height={props.height ? props.height : 44}
       fill={props.color ? props.color : '#C5CCE3'}
       viewBox="0 0 44 44"
       xmlns="http://www.w3.org/2000/svg"
       className={'attention-outline-icon'}
  >
    {/*<path*/}
    {/*  d="M21.9994 3.66699C18.3734 3.66699 14.8288 4.74222 11.8139 6.75672C8.799 8.77121 6.44917 11.6345 5.06157 14.9845C3.67396 18.3344 3.3109 22.0207 4.01829 25.577C4.72569 29.1333 6.47177 32.4 9.03573 34.9639C11.5997 37.5279 14.8664 39.274 18.4227 39.9814C21.979 40.6888 25.6652 40.3257 29.0152 38.9381C32.3652 37.5505 35.2285 35.2007 37.243 32.1858C39.2575 29.1709 40.3327 25.6263 40.3327 22.0003C40.3327 19.5928 39.8585 17.2088 38.9371 14.9845C38.0158 12.7602 36.6654 10.7391 34.963 9.0367C33.2606 7.33429 31.2395 5.98387 29.0152 5.06253C26.7909 4.1412 24.4069 3.66699 21.9994 3.66699ZM21.9994 36.667C19.0986 36.667 16.2629 35.8068 13.851 34.1952C11.4391 32.5836 9.55921 30.293 8.44912 27.613C7.33904 24.933 7.04859 21.9841 7.61451 19.139C8.18042 16.2939 9.57729 13.6806 11.6285 11.6294C13.6796 9.57826 16.293 8.18139 19.138 7.61547C21.9831 7.04956 24.9321 7.34001 27.612 8.45009C30.292 9.56018 32.5826 11.44 34.1942 13.852C35.8058 16.2639 36.666 19.0995 36.666 22.0003C36.666 25.8902 35.1208 29.6207 32.3703 32.3712C29.6197 35.1218 25.8892 36.667 21.9994 36.667Z"*/}
    {/*/>*/}
    {/*<path*/}
    {/*  d="M21.9993 31.1667C23.0119 31.1667 23.8327 30.3459 23.8327 29.3333C23.8327 28.3208 23.0119 27.5 21.9993 27.5C20.9868 27.5 20.166 28.3208 20.166 29.3333C20.166 30.3459 20.9868 31.1667 21.9993 31.1667Z"*/}
    {/*/>*/}
    {/*<path*/}
    {/*  d="M21.9993 12.833C21.5131 12.833 21.0468 13.0262 20.703 13.37C20.3592 13.7138 20.166 14.1801 20.166 14.6663V23.833C20.166 24.3192 20.3592 24.7856 20.703 25.1294C21.0468 25.4732 21.5131 25.6663 21.9993 25.6663C22.4856 25.6663 22.9519 25.4732 23.2957 25.1294C23.6395 24.7856 23.8327 24.3192 23.8327 23.833V14.6663C23.8327 14.1801 23.6395 13.7138 23.2957 13.37C22.9519 13.0262 22.4856 12.833 21.9993 12.833Z"*/}
    {/*/>*/}
    <path
      d="M18.9993 0.666626C8.87479 0.666626 0.666016 8.8754 0.666016 19C0.666016 29.1245 8.87479 37.3333 18.9993 37.3333C29.1239 37.3333 37.3327 29.1245 37.3327 19C37.3327 8.8754 29.1239 0.666626 18.9993 0.666626ZM22.8188 29.0821C21.873 29.4519 21.1213 29.7368 20.5574 29.9308C19.9936 30.1249 19.3449 30.2219 18.5992 30.2219C17.4594 30.2219 16.5682 29.943 15.9317 29.3852C15.3012 28.8335 14.9859 28.1242 14.9859 27.2633C14.9859 26.9299 15.0102 26.5903 15.0587 26.2448C15.1011 25.8931 15.1799 25.5051 15.283 25.0626L16.4652 20.8915C16.5682 20.4914 16.6592 20.1155 16.7319 19.7578C16.8047 19.4001 16.835 19.0727 16.835 18.7756C16.835 18.2482 16.7259 17.8723 16.5076 17.6662C16.2833 17.454 15.865 17.3509 15.2466 17.3509C14.9435 17.3509 14.6282 17.3994 14.3069 17.4904C13.9856 17.5874 13.7128 17.6783 13.4824 17.7632L13.7976 16.4779C14.5676 16.1627 15.3072 15.8959 16.0165 15.6716C16.7198 15.4473 17.3928 15.3321 18.0172 15.3321C19.1509 15.3321 20.03 15.611 20.6423 16.1566C21.2546 16.7022 21.5638 17.4176 21.5638 18.2906C21.5638 18.4725 21.5456 18.7938 21.5032 19.2485C21.4608 19.7032 21.382 20.1215 21.2668 20.5096L20.0906 24.6685C19.9936 25.0019 19.9087 25.3839 19.8299 25.8083C19.7572 26.2327 19.7208 26.56 19.7208 26.7783C19.7208 27.33 19.8421 27.7119 20.0906 27.912C20.3331 28.1121 20.7636 28.2091 21.3759 28.2091C21.6608 28.2091 21.9822 28.1606 22.3459 28.0636C22.7036 27.9605 22.9643 27.8756 23.128 27.7968L22.8188 29.0821ZM22.6066 12.1977C22.061 12.707 21.4001 12.9616 20.6302 12.9616C19.8602 12.9616 19.1934 12.707 18.6417 12.1977C18.09 11.6885 17.8171 11.0701 17.8171 10.3486C17.8171 9.62716 18.096 9.00272 18.6417 8.48739C19.1934 7.97207 19.8602 7.71744 20.6302 7.71744C21.4001 7.71744 22.061 7.97207 22.6066 8.48739C23.1583 9.00272 23.4311 9.62716 23.4311 10.3486C23.4311 11.0701 23.1583 11.6885 22.6066 12.1977Z"
    />
  </svg>
)

export default AttentionOutline
