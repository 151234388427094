import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Spin, Row, Col, Tooltip, Collapse, Steps } from 'antd';

import ModalShopifyCharge from './ModalShopifyCharge';
import BundleCard from './BundleCard';

import ShopifyStoreCreators from './reducer';

import utils from '../../Utils/utils';

import '../../Components/DashboardWelcome/styles.less';
import './styles.less';
import Icon from '../../Icon';

const { Panel } = Collapse;
const { Step } = Steps;
const redirectUri = `${process?.env?.REACT_APP_BASE_API_ROUTE}/user/webhooks/verify/`;

const ShopifyApp = (
  {
    isMobile,
    shopifyStore,
    ...props
  }) => {
  const { t } = useTranslation();
  const { search } = document.location;
  const shopName = utils.getSearchParamsByName(search, 'shop=');
  const charge_id = utils.getSearchParamsByName(search, 'charge_id=');


  const [visibleModal, setVisibleModal] = useState(false);
  const [selectBundle, setSelectBundle] = useState(null);

  const { checkChargeResult } = shopifyStore;

  useEffect(() => {
    document.title = 'Shopify App - Dropship';
    if (search) {
      if (search.includes('code')) {
        fetch(redirectUri + search, {
          headers: {
            mode: 'no-cors',
            cache: 'no-cache',
            credentials: 'include',
          },
        })
          .then(response => response.json())
          .then(data => console.log('response _ data:', data))
          .catch(error => console.error('response _ error:', error));
      }

      if (charge_id) {
        props.checkChargeShopify(charge_id);
      }

      if (shopName) {
        props.getBundles();
        props.getShopInfo(shopName);
      }

    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (checkChargeResult && shopName) props.getShopInfo(shopName);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkChargeResult]);

  const handleCloseModal = () => {
    setVisibleModal(false);
    setSelectBundle(null);
  };

  const handleSelect = (bundle) => {
    setSelectBundle(bundle);
    setVisibleModal(true);
  };

  return (
    <div className="shopify_app-page">
      {
        shopName
          ? (
            <Helmet>
              <meta httpEquiv="Content-Security-Policy"
                    content={`frame-ancestors https://${shopName} https://admin.shopify.com;`}
              />
            </Helmet>)
          : null
      }
      <Spin spinning={shopifyStore.loading || shopifyStore.shopLoading}>

        <div className="top-line">
          <h2 className="title">Thanks for installing Dropship Product Research</h2>

          {/*<Button
            type="primary"
            className="btn-primary border-only"
            href={process?.env?.REACT_APP_BASE_URI}
            target="_blank"
          >
            {t('Get Started')}
          </Button>*/}
        </div>

        <Collapse
          expandIcon={() =>
            <span className="anticon anticon-right ant-collapse-arrow">
              <Icon role={'icon'} type={'arrow_select_down'}/>
            </span>
          }
          expandIconPosition={'right'}
        >
          <Panel header={
            <>
              <span className='title'>Set-up instruction.</span>
              <div className='description'>
                Follow this short guide to get started with installing Dropship Product Research App and integrate Dropship website with Shopify.
              </div>
            </>
          }
                 key="1">
            <Steps direction={'vertical'} size="small" current={1}>
              <Step status="process"
                    description={
                      <span>
                       Sign up on <a href={process?.env?.REACT_APP_BASE_URI || '#'}
                                     target='_blank'
                                     rel="noopener noreferrer">Dropship website</a> and connect your Shopify store through Settings page.
                      </span>
                    }
              />
              <Step status="process"
                    description={
                      <span>
                        Connect Dropship with Shopify by installing Dropship Product Research App.
                      </span>
                    }
              />
              <Step status="process"
                    description={
                      <span>
                        On the Dropship website open a Product page and start importing products to Shopify stores.
                      </span>
                    }
              />
            </Steps>
          </Panel>
        </Collapse>

        <div className="welcome-block">

          <div className="user-detailed">
            <span className="title">{t('Current limits')}</span>
          </div>

          <div className="info-block-wrapper">
            <div className="info-block">
              <span className="text">{t('Free Monthly Imports')}</span>
              <Tooltip placement="top"
                       title={t('Updates every first day of a new month.')}
                       arrowPointAtCenter={true}
              >
              <span className="numbers" style={{ width: 'fit-content' }}>
                {`${shopifyStore?.shop?.limits?.used_free || 0}/${shopifyStore?.shop?.limits?.free_limit || 0}`}
              </span>
              </Tooltip>
            </div>

            <div className="divider"/>

            <div className="info-block">
              <span className="text">{t('Purchased Imports')}</span>
              <Tooltip placement="top"
                       title={t('Can be used with no date attached up to the full usage.')}
                       arrowPointAtCenter={true}
              >
              <span className="numbers" style={{ width: 'fit-content' }}>
                {`${shopifyStore?.shop?.limits?.used_paid || 0}/${shopifyStore?.shop?.limits?.paid_limit || 0}`}
              </span>
              </Tooltip>

            </div>
          </div>
        </div>

        <Row gutter={[{ sm: 0, md: 16 }, { xs: 128, sm: 128, md: 0 }]} style={{ marginTop: '100px' }}>
          {
            shopifyStore?.shop && shopifyStore?.bundles.map((el, index) => (
              <Col xs={24} sm={24} md={8} key={el.id}>
                <BundleCard
                  bundle={el}
                  selected={selectBundle}
                  bundleIndex={index}
                  onSelect={handleSelect}
                  //maxProducts={plan.maxProducts}
                />
              </Col>
            ))
          }
          {isMobile ? <Col xs={24} sm={24} md={8}/> : null}
        </Row>

        <ModalShopifyCharge
          isVisible={visibleModal}
          closeModal={handleCloseModal}
          data={{
            bundle_id: selectBundle?.id,
            shop_id: shopifyStore?.shop?.id,
            store: shopName,
          }}
        />
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  shopifyStore: state.shopifyStore,
});
const mapDispatchToProps = (dispatch) => ({
  getBundles: () => dispatch(ShopifyStoreCreators.getBundlesRequest()),
  getShopInfo: shop_domain => dispatch(ShopifyStoreCreators.getShopifyStoreInfoRequest({ shop_domain })),
  checkChargeShopify: charge_id => dispatch(ShopifyStoreCreators.checkChargeShopifyRequest({ charge_id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopifyApp);
