import React from 'react';

const Warning = (props) => (
  <svg
    width={props.width ? props.width : '24'}
    height={props.height ? props.height : '24'}
    fill={props.color ? props.color : '#707ba0'}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.5591 16.2997L14.8891 3.57969C14.579 3.09451 14.1517 2.69522 13.6466 2.41863C13.1415 2.14205 12.575 1.99707 11.9991 1.99707C11.4233 1.99707 10.8567 2.14205 10.3516 2.41863C9.84657 2.69522 9.41927 3.09451 9.10912 3.57969L1.43912 16.2997C1.16818 16.7513 1.02091 17.2664 1.01214 17.793C1.00336 18.3196 1.13338 18.8393 1.38912 19.2997C1.6848 19.818 2.11278 20.2484 2.62932 20.5471C3.14587 20.8458 3.73245 21.002 4.32912 20.9997H19.6691C20.2619 21.006 20.8458 20.8559 21.362 20.5644C21.8782 20.273 22.3084 19.8505 22.6091 19.3397C22.8724 18.8745 23.0066 18.3474 22.9978 17.8129C22.989 17.2784 22.8375 16.756 22.5591 16.2997ZM20.8591 18.3497C20.7389 18.5563 20.5645 18.7261 20.3548 18.8406C20.145 18.9552 19.9079 19.0102 19.6691 18.9997H4.32912C4.09035 19.0102 3.85324 18.9552 3.64348 18.8406C3.43372 18.7261 3.25931 18.5563 3.13912 18.3497C3.05136 18.1977 3.00515 18.0252 3.00515 17.8497C3.00515 17.6742 3.05136 17.5017 3.13912 17.3497L10.8191 4.61969C10.9571 4.4374 11.1355 4.28956 11.3402 4.18777C11.545 4.08598 11.7705 4.033 11.9991 4.033C12.2278 4.033 12.4533 4.08598 12.658 4.18777C12.8628 4.28956 13.0411 4.4374 13.1791 4.61969L20.8491 17.3397C20.9403 17.492 20.9893 17.6658 20.991 17.8433C20.9928 18.0208 20.9473 18.1956 20.8591 18.3497Z" />
    <path d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z" />
    <path d="M12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13V9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8Z" />
  </svg>
);

export default Warning;
