import React from 'react';
import { NavLink } from 'react-router-dom';
import cls from 'classname';
import Icon from '../../Icon';

export const NavLinkWithIcon = ({
  url,
  iconType,
  text,
  active,
  theme,
  isOpened,
  isSoon,
  markText,
  openSidebar,
  ...props
}) => (
  <NavLink
    // onMouseEnter={() => onHover(true)}
    // onMouseLeave={() => onHover(false)}
    to={url}
    onClick={(e) => {
        if (isSoon) {
          return (e.preventDefault())
        } else {
          if (openSidebar)
            openSidebar()
          return (e)
        }
    }}
    className={cls('sidebar-link', { disabled: isSoon })}
    activeClassName="selected"
  >
    <Icon
      className="sidebar-link_icon"
      type={iconType}
      role="icon"
      outline={active}
      theme={theme}
      {...props}
    />
    <span
      className={cls('sidebar-link_text', {
        'is-open': !isOpened,
        'is-closed': isOpened,
      })}
    >
      {text}
      {!!markText ? (
        <span className="sidebar-link_mark">{markText}</span>
      ) : null}
    </span>
  </NavLink>
);

export const NavLinkOnlyIcon = ({
  url,
  iconType,
  active,
  theme,
  isOpened,
  ...props
}) => (
  <NavLink to={url} className="sidebar-link" activeClassName="selected">
    <div className="sidebar-link_wrap">
      <Icon
        className="sidebar-link_icon"
        type={iconType}
        role="icon"
        outline={active}
        theme={theme}
        {...props}
      />
    </div>
  </NavLink>
);

export const navIconsArr = {
  desktop: [
    {
      url: '/dashboard',
      iconMob: 'dashboard_mobile',
      iconDesk: 'home',
      text: 'sidebar.dashboard',
    },
    {
      url: '/product_database',
      iconMob: 'database_mobile',
      iconDesk: 'database',
      text: 'Product Database',
      markText: 'new'
    },
    {
      url: '/booster',
      iconMob: 'database',
      iconDesk: 'gps',
      text: 'Sales Tracker',
      comingSoon: true,
      markText: 'soon',
    },
    {
      url: '/competitor_research',
      iconMob: 'competitor_mobile',
      iconDesk: 'competitor',
      text: 'sidebar.competitor_research',
      comingSoon: false,
      withDivider: true,
    },
    {
      url: '/portfolio',
      iconMob: 'portfolio_mobile',
      iconDesk: 'portfolio',
      text: 'sidebar.portfolio',
    },
    {
      url: '/collections',
      iconMob: 'collection_mobile',
      iconDesk: 'folder',
      text: 'sidebar.collections',
    },
    /*{
      url: '/upcoming-drops',
      iconMob: 'upcoming_drops_mobile',
      iconDesk: 'calendar',
      text: 'sidebar.upcoming_drops',
    },*/
  ],
  mobile: [
    {
      url: '/dashboard',
      iconMob: 'dashboard_mobile',
      iconDesk: 'home',
      text: 'sidebar.dashboard',
    },
    {
      url: '/portfolio',
      iconMob: 'portfolio_mobile',
      iconDesk: 'portfolio',
      text: 'sidebar.portfolio',
    },
    {
      url: '/collections',
      iconMob: 'collection_mobile',
      iconDesk: 'folder',
      text: 'sidebar.collections',
    },
   /* {
      url: '/upcoming-drops',
      iconMob: 'upcoming_drops_mobile',
      iconDesk: 'calendar',
      text: 'sidebar.upcoming_drops',
    },*/
    {
      url: '/competitor_research',
      iconMob: 'competitor_mobile',
      iconDesk: 'competitor',
      text: 'sidebar.competitor_research',
      comingSoon: false,
    },
    {
      url: '/setting',
      iconMob: 'settings_mobile',
      iconDesk: 'settings',
      text: 'sidebar.settings',
    },
  ],
};

export const navIconsAdmin = [
  {
    url: '/admin/portfolio',
    iconMob: 'portfolio_mobile',
    iconDesk: 'portfolio',
    text: 'Portfolio',
  },
  // {
  //   url: '/admin/alidrops',
  //   iconMob: 'ali_drops_mobile',
  //   iconDesk: 'ali_drops',
  //   text: 'Ali Drops',
  // },
  {
    url: '/admin/database',
    iconMob: 'database_mobile',
    iconDesk: 'database',
    text: 'Database',
    comingSoon: true,
    markText: 'soon',
  },
  {
    url: '/admin/droptimes',
    iconMob: 'upcoming_drops_mobile',
    iconDesk: 'calendar',
    text: 'Drop Times',
  },
  {
    url: '/admin/bd',
    iconMob: 'b_d_mobile',
    iconDesk: 'b_d',
    text: 'Benefits & Drawbacks',
  },
  {
    url: '/admin/shopify',
    iconMob: 'ali_drops_mobile',
    iconDesk: 'ali_drops',
    text: 'Shopify',
  },
];
