import React, { useState } from 'react';
import cls from 'classname';
import PropTypes from 'prop-types';
import Icon from '../../Icon';

import './styles.less';

export const ThemeSwitcher = ({ theme, changeTheme }) => {
  const [activeTheme, setTheme] = useState(theme);
  const onClickHandler = () => {
    const chooseTheme = activeTheme === 'light' ? 'dark' : 'light';

    changeTheme(chooseTheme);
    setTheme(chooseTheme);
  };

  return (
    <div className="theme-switcher" onClick={onClickHandler}>
      <div className={cls('theme-switcher_toggle', {
             dark: activeTheme === 'dark',
           })}
      />
      <div
        className={cls('theme-switcher_button theme-switcher_button--light', {
          active: activeTheme === 'light',
        })}
      >
        <Icon outline={activeTheme === 'light'} type="sun" role="icon" />
      </div>
      <div
        className={cls('theme-switcher_button theme-switcher_button--dark', {
          active: activeTheme === 'dark',
        })}
      >
        <Icon outline={activeTheme === 'dark'} type="moon" role="icon" />
      </div>
    </div>
  );
};

ThemeSwitcher.defaultProps = {
  theme: 'light',
};

ThemeSwitcher.propTypes = {
  theme: PropTypes.string,
  changeTheme: PropTypes.func.isRequired,
};

export default ThemeSwitcher;
