import React, { Component } from 'react';
import cls from 'classname';

import './styles.less';
import Icon from '../../Icon';

const slider = React.createRef();
const container = React.createRef();
const isTouchDevice = 'ontouchstart' in document.documentElement;

export default class ReactSwipeButton extends Component {
  isDragging = false;

  sliderLeft = 0;

  state = {};

  componentDidMount() {
    if (isTouchDevice) {
      document.addEventListener('touchmove', this.onDrag);
      document.addEventListener('touchend', this.stopDrag);
    } else {
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    }
    this.containerWidth =
      container.current.clientWidth - (this.props.isMobile ? 72 : 48);
  }

  onDrag = (e) => {
    if (this.unmounted || this.state?.unlocked) return;
    if (this.isDragging) {
      if (isTouchDevice) {
        if (e.touches?.length) {
          this.sliderLeft = Math.min(
            Math.max(0, e.touches[0].clientX - this.startX),
            this.containerWidth,
          );
        }
      } else {
        this.sliderLeft = Math.min(
          Math.max(0, e.clientX - this.startX),
          this.containerWidth,
        );
      }
      this.updateSliderStyle();
    }
  };

  updateSliderStyle = () => {
    if (slider.current) {
      slider.current.style.left = `${
        this.sliderLeft + (this.props.isMobile ? 72 : 48)
      }px`;
    }
    // if (this.unmounted || this.state.unlocked) return;
  };

  stopDrag = () => {
    if (this.unmounted || this.state.unlocked) return;
    if (this.isDragging) {
      this.isDragging = false;
      if (this.sliderLeft > this.containerWidth * 0.9) {
        this.sliderLeft = this.containerWidth;
        this.onSuccess();
        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
      } else {
        this.sliderLeft = 0;
        if (this.props.onFailure) {
          this.props.onFailure();
        }
      }
      this.updateSliderStyle();
    }
  };

  startDrag = (e) => {
    if (this.unmounted || this.state?.unlocked) return;
    this.isDragging = true;
    if (isTouchDevice) {
      if (e.touches?.length) {
        this.startX = e.touches[0].clientX;
      }
    } else {
      this.startX = e.clientX;
    }
  };

  onSuccess = () => {
    container.current.style.width = `${container.current.clientWidth}px`;
    this.setState({
      unlocked: true,
    });
  };

  getText = () =>
    this.state?.unlocked
      ? this.props.text_unlocked || 'UNLOCKED'
      : this.props.text || 'SLIDE';

  reset = () => {
    if (this.unmounted) return;
    this.setState({ unlocked: false }, () => {
      this.sliderLeft = 0;
      this.updateSliderStyle();
    });
  };

  componentWillUnmount() {
    this.unmounted = true;
    if (isTouchDevice) {
      document.removeEventListener('touchmove', this.onDrag);
      document.removeEventListener('touchend', this.stopDrag);
    } else {
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    }
  }

  render() {
    return (
      <div className="react-swipe-button">
        <div
          className={cls('rsb-container', {
            'red': this.props?.deleteType,
            'rsb-container-unlocked': this.state?.unlocked,
            'with-animation-hover': true,
          })}
          ref={container}
        >
          <div
            className="rsbc-slider"
            ref={slider}
            onMouseDown={this.startDrag}
            onTouchStart={this.startDrag}
          >
            <span className="rsbc-slider-circle">
              <Icon
                role="icon"
                type="arrow_slider_next"
                className="rsbc-slider-arrow"
              />
            </span>
          </div>
          <div className="rsbc-text">{this.getText()}</div>
          <span className="rsbc-slider-circle_empty" />
        </div>
      </div>
    );
  }
}
