import { takeLatest, put, call } from 'redux-saga/effects';

import * as services from './services';
import Creators, { ProductDatabaseTypes as constants } from './reducer';

const actions = {
  getProductDatabaseFiltersActions: {
    request: Creators.getProductDetailsRequest,
    success: Creators.getProductDetailsSuccess,
    errors: Creators.getProductDetailsFailure,
  }
};

const eventsOptions = {
  [constants.GET_PRODUCT_DATABASE_FILTERS_REQUEST]: {
    api: services.getProductDatabaseFilters,
    actions: actions.getProductDatabaseFiltersActions,
  }
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if (response.data) {
      yield put(provider.actions.success(response.data));
    } else {
      yield put(provider.actions.errors({ errors: 'some error' }));
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_PRODUCT_DATABASE_FILTERS_REQUEST, apiGenerator);
}
