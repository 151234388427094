import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classname';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'antd';
import { Menu } from './components';
import Icon from '../../Icon';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';

import actions from './actions';
import ShopifyStoreCreators from '../ShopifyStore/reducer';

import './styles.less';
import Creators from '../ShopifyStore/reducer';

export const UserComponent = ({ user, nav, shopifyStore, ...props }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const {
    first_name: firstName,
    last_name: lastName,
    role,
    subscriptions,
  } = user;

  const { theme, isMobile } = nav;
  const { logout, changeTheme } = props;
  const planName = subscriptions[0] ? subscriptions[0]?.plan?.name : '';
  const disabledConnect = !subscriptions?.[0]?.plan?.monthly_price
    && subscriptions?.[0]?.plan?.name !== 'free'
    && role?.id !== 4;
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');

  const handleVisibleChange = (value) => {
    if (value && !user.isAdmin) props.getShopifyStore();
    setVisible(value);
  };

  const handleOpenModalShopifyConnect = () => {
    props.openModalShopifyConnect(initialUrl);
    setVisible(false);
  };

  return (
    <div className="user-dropdown-wrap">
      <Dropdown
        //getPopupContainer={() => dropdown.current}
        destroyPopupOnHide={true}
        getPopupContainer={() => document.getElementById('global-wrap')}
        onVisibleChange={handleVisibleChange}
        overlay={
          <Menu
            theme={theme}
            changeTheme={changeTheme}
            logout={logout}
            isAdmin={role?.name === 'Admin'}
            isMobile={isMobile}
            shopifyStore={shopifyStore}
            openModalShopifyConnect={handleOpenModalShopifyConnect}
            disabledConnect={disabledConnect}
          />
        }
        trigger={['click']}
        visible={visible}
      >
        <span className={cls({close: !visible})} style={{ cursor: 'pointer' }}>
          <Icon className="avatar-image" type="logo_simple" role="icon"/>
          <div className="user-details">
            <span className="user-details_name">
              {`${firstName} ${lastName}`}
              <ArrowSelectDown/>
            </span>
            <span className="user-details_plan">{`${t('_planName_ plan', { planName: planName })}`}</span>
          </div>

        </span>
      </Dropdown>
    </div>
  );
};

UserComponent.defaultProps = {};

UserComponent.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  nav: PropTypes.shape({
    theme: PropTypes.string,
    isMobile: PropTypes.bool,
  }),
  avatar: PropTypes.string,
  changeTheme: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  shopifyStore: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.userInfo,
  nav: state.nav,
  shopifyStore: state.shopifyStore,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(actions.logout()),
  changeTheme: theme => dispatch(actions.changeTheme('theme', theme)),
  getShopifyStore: () => dispatch(ShopifyStoreCreators.getShopifyStoresRequest()),
  openModalShopifyConnect: initialUrl => dispatch(Creators.changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);
