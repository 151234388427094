/*
 *
 * Shopify services
 *
 */

import { API } from '../../Services/Api';

const request = new API();

// export const getShopifyProductsNext = ({ link }) => {
//   const uri = new URL(link);
//   uri.protocol = 'https://';
//   return request.api.get(uri.toString(), null);
// };
export const getShopifyProductsNext = ({ isAdmin, ...data} ) => {
  const uri = isAdmin ? `/admin-panel/competitors/` : `/shopify_search/`;
  return request.api.post(uri, { ...data });
};

export const getShopifyProducts = ({ search, isAdmin, ...params }) => {
  const p = new URLSearchParams(search);
  const uri = isAdmin ? `/admin-panel/competitors/` : `/shopify_search/`;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(params)) {
    p.append(key, value);
  }
  return request.api.get(`${uri}?${p.toString()}`, null);
};

export const getShopifyCount = () => {
  return request.api.get(`/admin-panel/competitors_count/`);
};
