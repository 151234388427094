import React, {useState} from 'react';
import cls from 'classname';

export const SearchAlibaba = ({ className }) => {
  const [reversedIcon, setReversedIcon] = useState(false)

  return (
  <svg width="36"
       height="18"
       fill="none"
       className={cls('icon', className)}
       viewBox="0 0 36 18"
       xmlns="http://www.w3.org/2000/svg"
       onMouseEnter={() => setReversedIcon(true)}
       onMouseLeave={() => setReversedIcon(false)}
  >
    <path fillRule="evenodd" clipRule="evenodd"
          d="M25.6946 3.94845L25.6628 3.5686C25.3784 2.5554 23.4184 1.7322 19.7827 1.16235C20.1937 1.44728 20.5098 1.76392 20.6679 2.04884L20.8576 2.5554L13.7129 4.26501L8.97085 5.59478L9.63476 7.1461L9.28695 7.68438L8.0857 8.66587L7.29531 9.04571L6.53648 8.57079L6.7264 11.8952C5.1457 12.2434 4.13398 12.9084 3.72312 13.8898L3.5332 14.4596C3.37515 15.1877 3.59648 15.726 4.26047 16.106C3.72312 15.4728 3.6282 14.8711 3.97593 14.2696C4.16546 13.9848 4.38687 13.6998 4.73461 13.4464C5.36687 12.9083 6.09406 12.5917 6.85265 12.4334H7.16875L7.32679 9.3622L9.445 9.9322L11.6896 9.83712C12.385 9.64712 13.0173 9.39392 13.5864 9.04564C14.1555 8.729 14.6929 8.31751 15.1671 7.81087C15.4201 8.00087 15.5782 8.60236 15.6413 9.58384L15.7677 8.66571C15.7677 7.81087 15.3568 7.20931 14.598 6.82931L15.0722 7.39931L14.7561 7.65251C14.1238 8.19079 13.4284 8.60228 12.7012 8.91892L12.4168 9.01384C11.5315 9.33048 10.6148 9.42556 9.69781 9.36212L9.28687 9.29892L9.19211 9.2672C8.84437 9.23556 8.55984 9.10892 8.33851 8.91892L10.2038 7.65251C10.1089 7.14595 9.95078 6.63931 9.72961 6.1961L9.66625 6.1011L9.5082 5.9111C9.69773 5.9111 10.678 5.62626 12.543 4.99298C14.3766 4.35985 17.3168 3.63149 21.3 2.80845L20.7943 1.60532C23.7345 2.3022 25.3784 3.09368 25.6946 3.94845Z"
          fill={reversedIcon ? '#225aea' : "black"}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M35.2416 11.9585C29.5511 14.0481 25.5678 15.2195 23.2915 15.4095C20.889 15.6312 20.6992 14.618 22.7225 12.4334L25.1568 9.55235C25.7258 8.88742 26.2 8.25422 26.5795 7.55781C28.0337 5.05649 28.0337 3.09367 26.5795 1.66899L26.4531 1.51071C25.9471 1.06742 25.3781 0.719143 24.7141 0.497424C23.2915 -0.0407008 21.3947 -0.135701 19.1185 0.180862C15.5779 0.624065 12.0371 1.89047 8.55964 3.98008L8.21182 4.17008C4.86073 6.19641 2.48971 8.41258 1.0987 10.8188C-0.387081 13.32 -0.355597 15.283 1.13034 16.7077L2.39487 17.6258L2.6162 17.7207C4.1969 18.3223 5.87237 18.5439 7.6426 18.3223C9.85557 18.069 12.1951 17.1826 14.7242 15.6313L15.3249 15.2195L14.7242 15.4096C11.5312 16.4228 8.84409 16.866 6.66276 16.7077L4.76589 16.3277L4.63932 16.2645L4.2601 16.1062C3.59612 15.7262 3.37479 15.1879 3.53284 14.4598L3.72276 13.8899C4.1337 12.9084 5.14534 12.2436 6.72604 11.8953L6.53612 8.57094L7.29495 9.04586L8.08534 8.66602L9.28659 7.68453L9.6344 7.14625L8.97049 5.59492L13.7125 4.26516L20.8572 2.55555L20.6675 2.04899C20.5095 1.76414 20.1933 1.4475 19.7824 1.1625C23.418 1.73235 25.3781 2.55555 25.6624 3.56875L25.6942 3.9486V4.36032L25.5677 5.05672C25.3149 5.785 24.809 6.67141 24.0186 7.62125L20.5411 11.5155C19.561 12.4337 18.7707 13.7001 18.2017 15.3148C17.5695 16.8978 18.2332 17.7209 20.1301 17.7526C21.9954 17.7526 24.5246 17.1827 27.6226 16.0429C30.4362 14.9665 32.9653 13.6684 35.2415 12.1803L35.937 11.6739L35.2416 11.9585Z"
          fill={reversedIcon ? '#225aea' : "#F08200"}/>
  </svg>
)}

export default SearchAlibaba;
