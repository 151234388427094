import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router, Switch, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { Layout, Spin } from 'antd';
import { withResizeDetector } from 'react-resize-detector';
import { history } from '../../Utils/utils';
import i18n from '../../i18n';
import moment from 'moment';

// COMPONENTS
import { PrivateRoute } from '../../Components/PrivateRoute';
import NotFoundPage from '../NotFoundPage';

import CustomLayout from '../Layout';
import ShopifyApp from '../ShopifyStore';
import ConnectShopifyStoreVerifyPage from '../ConnectShopifyStoreVerifyPage';
import openNotification from '../../Components/Notification';

// REDUCERS
import NavActions from '../../Redux/NavRedux';
import ErrorsActions from '../../Redux/ErrorsRedux';
import AuthActions from '../LoginPage/reducer';

// HOC
import useWindowSize from '../../hooks/use-window-size';
import withAuth from '../../hoc/withAuth';
import Spinner from '../../Components/Spinner';

function Routes(props) {
  const {
    width,
    auth,
    navSetProp,
    userInfo,
    isAdmin,
    error,
  } = props;

  const [, innerHeight] = useWindowSize();

  useEffect(() => {
    if (innerHeight) document.documentElement.style.setProperty('--app-height', `${innerHeight}px`);
  }, [innerHeight]);

  // ERRORS
  useEffect(() => {
    if (error) {
      if (error.message) {
        for (const i in error.message) {
          //message.error(`${i}: ${error.message[i]}`, 5);
          openNotification({
            type: 'error',
            message: `${i}: ${error.message[i]}`,
            style: { minWidth: '716px' },
            getContainer: userInfo?.onboarding_finished ? document.getElementById('global-wrap') : document.body,
            duration: 5,
            key: i,
          });
        }
      } else if (typeof error === 'string') {
        //message.error(error, 5);
        openNotification({
          type: 'error',
          message: error,
          style: { minWidth: '716px' },
          getContainer: userInfo?.onboarding_finished ? document.getElementById('global-wrap') : document.body,
          duration: 5,
          key: 'error notification',
        });
      } else {
        for (const i in error) {
          //message.error(`${i}: ${error[i]}`, 5);
          openNotification({
            type: 'error',
            message: `${i}: ${error[i]}`,
            style: { minWidth: '716px' },
            getContainer: userInfo?.onboarding_finished ? document.getElementById('global-wrap') : document.body,
            duration: 5,
            key: i,
          });
        }
      }
      props.errorReset();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [!!error]);

  // RESIZE
  useEffect(() => {
    const correctWidth = width || window.innerWidth;
    if (correctWidth > 768) {
      navSetProp('isDesktop', true);
      navSetProp('isMobile', false);
    } else {
      navSetProp('isDesktop', false);
      navSetProp('isMobile', true);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [width]);

  // SET LANGUAGE
  useEffect(() => {
    i18n.changeLanguage(userInfo?.language || 'en');
    moment.locale(userInfo?.language === 'zh-hans' ? 'zh-cn' : (userInfo?.language || 'en'));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [userInfo?.language]);


  Spin.setDefaultIndicator(<Spinner/>);

  return (
    <Router history={history}>
      <Layout className="main-layout">
        <Switch>
          <CustomLayout>
            <Route
              exact
              path="/"
              component={ShopifyApp}
            />
            <PrivateRoute
              exact
              path="/connect-shopify/verify"
              redirectPath="/login"
              auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}
              component={ConnectShopifyStoreVerifyPage}
            />
          </CustomLayout>
          <Route path="/*" component={NotFoundPage}/>
        </Switch>
      </Layout>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userInfo: state.auth.userInfo,
  startup: state.startup,
  isDesktop: state.nav.isDesktop,
  isMobile: state.nav.isMobile,
  isAdmin: state.auth.isAdmin,
  error: state.errors.data,
});

const mapDispatchToProps = (dispatch) => ({
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
  errorReset: () => dispatch(ErrorsActions.errorReset()),
  logout: () => dispatch(AuthActions.logout()),
});

Routes.propTypes = {
  userInfo: PropTypes.shape({
    onboarding_finished: PropTypes.bool,
    language: PropTypes.string,
  }),
  startup: PropTypes.shape({
    success: PropTypes.bool,
  }),
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  isAdmin: PropTypes.bool,
  error: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withResizeDetector, withAuth)(Routes));
