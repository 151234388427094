/*
 *
 * Products Page sagas
 *
 */

import { takeLatest, put, call } from 'redux-saga/effects';

import * as services from './services';
import Creators, { PortfolioTypes as constants } from './reducer';

const actions = {
  getShopifyProductsActions: {
    request: Creators.getShopifyProductsRequest,
    success: Creators.getShopifyProductsSuccess,
    errors: Creators.getShopifyProductsFailure,
  },
  getShopifyProductsNextActions: {
    request: Creators.getShopifyProductsNextRequest,
    success: Creators.getShopifyProductsNextSuccess,
    errors: Creators.getShopifyProductsNextFailure,
  },
  getShopifyCountActions: {
    request: Creators.getShopifyCountRequest,
    success: Creators.getShopifyCountSuccess,
    errors: Creators.getShopifyCountFailure,
  },
};

const eventsOptions = {
  [constants.GET_SHOPIFY_PRODUCTS_REQUEST]: {
    api: services.getShopifyProducts,
    actions: actions.getShopifyProductsActions,
  },
  [constants.GET_SHOPIFY_PRODUCTS_NEXT_REQUEST]: {
    api: services.getShopifyProductsNext,
    actions: actions.getShopifyProductsNextActions,
  },
  [constants.GET_SHOPIFY_COUNT_REQUEST]: {
    api: services.getShopifyCount,
    actions: actions.getShopifyCountActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if (response.data) {
      yield put(provider.actions.success(response.data));
    } else {
      yield put(provider.actions.errors({ errors: 'some error' }));
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_SHOPIFY_PRODUCTS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_SHOPIFY_PRODUCTS_NEXT_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_SHOPIFY_COUNT_REQUEST, apiGenerator);
}
