import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import en from 'antd/lib/locale-provider/en_GB';
import moment from 'moment';
import { history } from './Utils/utils';
import InitComponent from './Containers/InitComponent';
import 'moment/locale/de'; // important!
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/zh-cn';

import './index.less';
import './App.less';
import './Containers/LoginPage/styles.less';
import './video.less';

moment.updateLocale('en-gb', {
  weekdaysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  monthsShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
});

document.addEventListener('visibilitychange', () => {
  if (!document.visible) {
    document.activeElement.blur()
  }
})

function App({ store }) {
  return (
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <ConfigProvider locale={en}>
          <ConnectedRouter history={history}>
            <InitComponent />
          </ConnectedRouter>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

App.propTypes = {
  store: PropTypes.shape({
    store: PropTypes.object,
    persistor: PropTypes.object,
  }).isRequired,
};

export default App;
