import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const EmptyWarning = ({ width, height, className, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    className={cls(className, 'icon icon-empty-warning')}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.1602 43.4665L39.7069 9.5465C38.8798 8.25268 37.7403 7.1879 36.3935 6.45034C35.0466 5.71277 33.5358 5.32617 32.0002 5.32617C30.4646 5.32617 28.9537 5.71277 27.6069 6.45034C26.2601 7.1879 25.1206 8.25268 24.2935 9.5465L3.84019 43.4665C3.11766 44.6709 2.72496 46.0443 2.70155 47.4486C2.67815 48.853 3.02487 50.2387 3.70685 51.4665C4.49533 52.8485 5.63661 53.9965 7.01406 54.7929C8.3915 55.5894 9.95572 56.0059 11.5469 55.9998H52.4535C54.0342 56.0167 55.5914 55.6163 56.9679 54.8391C58.3444 54.0619 59.4915 52.9354 60.2935 51.5732C60.9955 50.3326 61.3533 48.9269 61.3299 47.5017C61.3064 46.0764 60.9026 44.6833 60.1602 43.4665ZM55.6269 48.9332C55.3064 49.4841 54.8413 49.9368 54.2819 50.2424C53.7225 50.5479 53.0903 50.6946 52.4535 50.6665H11.5469C10.9101 50.6946 10.2778 50.5479 9.71847 50.2424C9.15912 49.9368 8.69401 49.4841 8.37352 48.9332C8.13947 48.5278 8.01625 48.0679 8.01625 47.5998C8.01625 47.1317 8.13947 46.6719 8.37352 46.2665L28.8535 12.3198C29.2215 11.8337 29.6972 11.4395 30.2431 11.168C30.7891 10.8966 31.3905 10.7553 32.0002 10.7553C32.6099 10.7553 33.2113 10.8966 33.7573 11.168C34.3032 11.4395 34.7788 11.8337 35.1469 12.3198L55.6002 46.2398C55.8433 46.646 55.9739 47.1096 55.9786 47.5829C55.9832 48.0562 55.8619 48.5223 55.6269 48.9332Z"
      // fill={fill}
    />
    <path
      d="M32 45.3333C33.4727 45.3333 34.6666 44.1394 34.6666 42.6667C34.6666 41.1939 33.4727 40 32 40C30.5272 40 29.3333 41.1939 29.3333 42.6667C29.3333 44.1394 30.5272 45.3333 32 45.3333Z"
      // fill={fill}
    />
    <path
      d="M32 21.3333C31.2927 21.3333 30.6145 21.6142 30.1144 22.1143C29.6143 22.6144 29.3333 23.2927 29.3333 23.9999V34.6666C29.3333 35.3738 29.6143 36.0521 30.1144 36.5522C30.6145 37.0523 31.2927 37.3333 32 37.3333C32.7072 37.3333 33.3855 37.0523 33.8856 36.5522C34.3857 36.0521 34.6666 35.3738 34.6666 34.6666V23.9999C34.6666 23.2927 34.3857 22.6144 33.8856 22.1143C33.3855 21.6142 32.7072 21.3333 32 21.3333Z"
      // fill={fill}
    />
  </svg>
);

EmptyWarning.defaultProps = {
  width: 64,
  height: 64,
  fill: '#A1AAC8',
};

EmptyWarning.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default EmptyWarning;
