import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    getShopifyProductsRequest: ['payload'], // payload: { search } --> filters: month, drop_id
    getShopifyProductsSuccess: ['payload'], // payload: { data }
    getShopifyProductsFailure: ['payload'], // payload: { errors }

    updateShopifyProduct: ['payload'], // payload: { product }

    getShopifyProductsNextRequest: ['payload'], // payload: { link }
    getShopifyProductsNextSuccess: ['payload'], // payload: { data }
    getShopifyProductsNextFailure: ['payload'], // payload: { errors }

    getShopifyCountRequest: ['payload'], // payload: { search } --> filters: month, drop_id
    getShopifyCountSuccess: ['payload'], // payload: { data }
    getShopifyCountFailure: ['payload'], // payload: { errors }

    clearShopifyList: null,
  },
  { prefix: '/admin-panel/competitors/' },
);

export const PortfolioTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  countLoading: false,
  errors: false,
  products: [],
  count: null,
});

/* ------------- Functions for reducer cases ------------- */

const getShopifyProductsRequest = (state) => state.merge({ loading: true });

const getShopifyProductsSuccess = (state, { payload: data }) =>
  state.merge({ loading: false, products: data });

const getShopifyProductsFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });

const getShopifyProductsNextRequest = (state) => state.merge({ loading: true });

const getShopifyProductsNextFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });

const getShopifyProductsNextSuccess = (state, { payload: data }) => {
  const allData = state.products.results.concat(data.results);
  return state
    .merge({
      loading: false,
      products: { ...data, results: allData },
    })
    .setIn(['products', 'results'], allData);
};

const getShopifyCountRequest = (state) => state.merge({ countLoading: true });

const getShopifyCountSuccess = (state, { payload: data }) =>
  state.merge({ countLoading: false, count: data });

const getShopifyCountFailure = (state, { payload: { errors } }) =>
  state.merge({ countLoading: false, errors });

const clearShopifyList = (state) =>
  state.merge({ products: [], loading: false });

const updateShopifyProduct = (state, { payload: { product } }) => {
  let index;
  const products = state.products.results;

  products.forEach((value, key) => {
    if (value.product_id === product.product_id) index = key;
  });

  return state.setIn(['products', 'results', index], product);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SHOPIFY_PRODUCTS_REQUEST]: getShopifyProductsRequest,
  [Types.GET_SHOPIFY_PRODUCTS_SUCCESS]: getShopifyProductsSuccess,
  [Types.GET_SHOPIFY_PRODUCTS_FAILURE]: getShopifyProductsFailure,

  [Types.UPDATE_SHOPIFY_PRODUCT]: updateShopifyProduct,
  [Types.CLEAR_SHOPIFY_LIST]: clearShopifyList,

  [Types.GET_SHOPIFY_PRODUCTS_NEXT_REQUEST]: getShopifyProductsNextRequest,
  [Types.GET_SHOPIFY_PRODUCTS_NEXT_SUCCESS]: getShopifyProductsNextSuccess,
  [Types.GET_SHOPIFY_PRODUCTS_NEXT_FAILURE]: getShopifyProductsNextFailure,

  [Types.GET_SHOPIFY_COUNT_REQUEST]: getShopifyCountRequest,
  [Types.GET_SHOPIFY_COUNT_SUCCESS]: getShopifyCountSuccess,
  [Types.GET_SHOPIFY_COUNT_FAILURE]: getShopifyCountFailure,
});
