import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    navSetProp: ['key', 'value'],
    logout: null,
  },
);

export const NavTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isDesktop: false,
  isMobile: false,
  isShowMenu: false,
  isShowSideBar: false,
  tabPosition: { x: 0, y: 0 },
  theme: 'light',
});

/* ------------- Selectors ------------- */
// export const isOpenSidebar = (state) => state.nav.sidebarVisible

export const navSetPropRedux = (state, { key, value }) => {
  const obj = {};
  obj[key] = value;

  return state.merge(obj);
};

export const logout = (state) => state.merge({...INITIAL_STATE});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.NAV_SET_PROP]: navSetPropRedux,
  [Types.LOGOUT]: logout,
});
